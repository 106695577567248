import PropTypes from "prop-types";
import Icon from "./index";

const LetterIcon = (props) => Icon({
    ...props,
    iconName: "letter",
  });

LetterIcon.defaultProps = {
  isAriaHidden: true,
  alt: "letter",
};

LetterIcon.propTypes = {
  alt: PropTypes.string,
  isAriaHidden: PropTypes.bool,
  className: PropTypes.string,
};

export default LetterIcon;
