import "./style.scss";

import { useEffect, useState } from "react";

import PropTypes from "prop-types";
import Banner from "../../components/AccentBanner/Banner";
import Container from "../../components/Container";
import ContentWithSidebar from "../../components/Container/_contentWithSidebar";
import Layout from "..";
import SendMessage from "../../components/Messages/SendMessage";
import Sidebar from "../../components/Sidebar";
import SidebarList from "../../components/List/sidebarList";
import { createClassName } from "../../helpers";

const cn = createClassName("with-sideBar-page");

const WithSidebarPage = ({
  children,
  bannerTitle,
  bannerSubTitle,
  breadcrumsTitle,
  featureToolkit,
  ...props
}) => {
  const [isActiveLink, setActiveLink] = useState(0);
  const [titles, addTitles] = useState([]);

  useEffect(() => {
    let updateMounted = true; // fix error "Can't perform a React state update on an unmounted component..."

    const headings = Array.from(
      document.querySelectorAll(
        props.headingsClassName || ".with-sideBar-page__item-heading",
      ),
    );

    addTitles(headings.map((title) => ({ heading: title.id, id: title.children[0].id })));

    const changeActiveElement = (el, index) => {
      const itemPosition = el.getBoundingClientRect().top;
      itemPosition < 100 && itemPosition > -5 && setActiveLink(index);
    };

    const changeActiveLink = () => {
      updateMounted
        && headings.map((heading, index) => changeActiveElement(heading, index));
    };

    const listener = document.addEventListener(
      "scroll" || "touchmove",
      changeActiveLink,
      { passive: true },
    );

    return () => {
      updateMounted = false;
      document.removeEventListener("scroll" || "touchmove", listener, {
        passive: true,
      });
    };
  }, []);

  const isBanner = bannerTitle ? <Banner title={bannerTitle} subtitle={bannerSubTitle && bannerSubTitle} /> : null;

  return (
    <Layout {...props.seo}>
      {
        props.breadcrums ? props.breadcrums : isBanner
      }
      <Container className={`${props.className} ${cn("container", { wrap: props.breadcrums && true })}`}>
        {props.contentBeforeMainSection && props.contentBeforeMainSection}
        <Sidebar>
          {
            props.sidebarChildrenBefore && props.sidebarChildrenBefore
          }
          <SidebarList
            title={props.sidebarChildrenTitle}
            list={titles}
            active={+isActiveLink}
          />
          {(props.sidebarChildrenAdditional) && (
            <div className={cn("sidebar", { block: true })}>
              {props.sidebarChildrenAdditional}
            </div>
          )}
        </Sidebar>
        <ContentWithSidebar>
          {children}
        </ContentWithSidebar>
      </Container>
      {props.contentAfterMainSection && (
        <Container>{props.contentAfterMainSection}</Container>
      )}
      {props.withSendingBlock && <SendMessage />}
    </Layout>
  );
};

WithSidebarPage.defaultProps = {
  seoNoForRobots: false,
  className: "",
  withSendingBlock: false,
};
WithSidebarPage.propTypes = {
  seo: PropTypes.any.isRequired,
  seoNoForRobots: PropTypes.bool.isRequired,
  bannerTitle: PropTypes.string,
  breadcrumsTitle: PropTypes.string,
  bannerSubTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  contentBeforeMainSection: PropTypes.object,
  contentAfterMainSection: PropTypes.object,
  sidebarChildrenTitle: PropTypes.string.isRequired,
  sidebarChildrenAdditional: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  className: PropTypes.string,
  withDetailsBlock: PropTypes.object,
  questionsBlock: PropTypes.arrayOf(PropTypes.object),
};

export default WithSidebarPage;
