import PropTypes from "prop-types";
import Icon from "./index";

const CloseWindow = (props) => Icon({
    ...props,
    iconName: "close-window",
    id: "close-window",
  });

CloseWindow.defaultProps = {
  isAriaHidden: false,
  alt: "close window",
};

CloseWindow.propTypes = {
  alt: PropTypes.string,
  isAriaHidden: PropTypes.bool,
  className: PropTypes.string,
};

export default CloseWindow;
