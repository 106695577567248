import "./style.scss";

import { useCallback, useContext, useEffect, useRef, useState } from "react";

import CloseWindow from "../../Icons/CloseWindow";
import GeneralForm from "../../Forms/_main";
import LetterIcon from "../../Icons/LetterIcon";
import { LocaleContext } from "../../../layouts/LocaleContext";
import { createClassName } from "../../../helpers";
import { useTranslation } from "react-i18next/dist/es/useTranslation";

const cn = createClassName("sendMessage");

const SendMessage = () => {
  const { isMobileOnly } = useContext(LocaleContext);
  const [isOpenForm, setOpenForm] = useState(false);
  const sendLetterRef = useRef(null);
  const { t } = useTranslation("strings");

  const switchOpenForm = useCallback(() => {
    return setOpenForm(prevState => !prevState);
  }, []);

  useEffect(() => {
    sendLetterRef.current.addEventListener("click", switchOpenForm);
    return () => sendLetterRef.current?.removeEventListener("click", switchOpenForm);
  }, [isOpenForm]);

  useEffect(() => {
    const closeWindow = document.getElementById("close-window");
    closeWindow.addEventListener("click", () => setOpenForm(false));
    return () => closeWindow.removeEventListener("click", () => setOpenForm(false));
  }, [isOpenForm]);

  useEffect(() => {
    const header = document.querySelector(".header");
    header.style.display = (isMobileOnly && isOpenForm) ? "none" : null;
  }, [isOpenForm]);

  return (
    <div className={cn({ wrap: true, fullWidth: isOpenForm })}>
      <div className={cn({ wrap: "block" })}>
        <div className={cn("letter", { wrap: true })} ref={sendLetterRef}>
          <LetterIcon className={cn("letter", { icon: true })} />
        </div>
        <div className={cn("form", { wrap: true, show: isOpenForm })}>
          <CloseWindow className={cn("form", { btn: "close" })} />
          <GeneralForm
            fullWidthNotice
            withAttach={false}
            title={t("popupSendMessageTitle")}
            levelSubtitle={3}
            classNameForm={cn("form", { wrap: "form-section" })}
            classNameTextarea={cn("form", { textarea: true })}
          />
        </div>
      </div>
    </div>
  );
};

export default SendMessage;
