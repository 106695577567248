import PropTypes from "prop-types";

import Title from "../../Title";
import AnchorLink from "../../Links/anchorLink";

import { createClassName, createID } from "../../../helpers";
import "./style.scss";

const SidebarList = (props) => {
  const cn = createClassName("sidebar-list");

  const list = props.list.map((item, index) => (
    <li className={cn("item")} key={index}>
      <span className={cn("item", { wrap: true })}>
        <AnchorLink
          href={createID(item.id)}
          className={`${cn("item", {
            link: true,
            active: index === props.active,
          })} ${props.itemClassName}`}
          color="default"
        >
          {item.heading ? item.heading : item.id}
        </AnchorLink>
      </span>
    </li>
  ));

  return (
    <div className={`${cn({ wrap: true })} ${props.className}`}>
      <Title level={5} weight="medium" className={cn({ title: true })}>
        {props.title}
      </Title>
      <ul className={cn({ list: true })}>{list}</ul>
    </div>
  );
};

SidebarList.defaultProps = {
  className: "",
  itemClassName: "",
};

SidebarList.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.object),
  active: PropTypes.number.isRequired,
};

export default SidebarList;
