import "./style.scss";

import LinkOnResourceWithIcon from "../../Links/LinkOnResource/_withIcon";
import ArrowToRight from "../../Icons/ArrowToRight";
import { createClassName } from "../../../helpers";

const cn = createClassName("toolkit-link");

const ToolkitLink = (props) => (
  <div className={cn("sidebar", { beforeList: true })}>
    <p>
      {props.sidebarSubTitle}
    </p>
    <LinkOnResourceWithIcon
      href={props.href}
      color="primary"
      additionalText={props.sidebarAfterAccentTitle}
      icon={<ArrowToRight />}
      isTarget
      contentClassName={cn("sidebar", { beforeList: "link" })}
      content={props.featureToolkit}
    />
  </div>
  );

export default ToolkitLink;
